function getNewsList(filename, dir) {
    $.ajax({
        url: filename,
        type: 'GET',
        dataType: 'json'
    })
    .done(data => {
        let listResult = '';
        let yearResult = '';
        let years = [];
        data.forEach(v => {
            let categoryName = '';
            switch(v.category) {
                case 'news':
                    categoryName = 'お知らせ';
                    break;
                case 'campaign':
                    categoryName = 'キャンペーン';
                    break;
                case 'seminar':
                    categoryName = 'セミナー';
                    break;
                case 'important':
                    categoryName = '重要';
                    break;
            }

            let url = '';
            let target = '';
            let icon = '';
            switch(v.type) {
                case 'html':
                    url = '/' + dir + '/post_' + v.id + '.html';
                    target = '_self';
                    break;
                case 'pdf':
                    url = '/' + v.file.replace(/\\/, '');
                    target = '_blank';
                    icon = 'p-news-description--pdf';
                    break;
                case 'url':
                    url = v.url.replace(/\\/, '');
                    target = v.target;
                    if (target === '_blank') {
                        icon = 'p-news-description--external';
                    }
                    break;
            }
            const year = v.date.substr(0, 4);
            if (years.indexOf(year) === -1) years.push(year);
            listResult += '<li data-year="' + year + '"><a href="' + url + '" target="' + target + '"><span class="p-news-date">' + v.date + '</span><span class="c-label c-label--' + v.category + ' p-news-label">' + categoryName + '</span><br class="u-hide-pc"><span class="p-news-description ' + icon + '">' + v.title + '</span></a></li>';
        });
        
        years.sort((a,b) => { return b - a; });
        years.forEach(v => {
            yearResult += '<li><a href="#" class="c-button c-button--small c-button--square c-button--arrow-right" data-year-trigger="' + v + '">' + v + '年</a></li>';
        })

        $('#newsList').append(listResult);
        $('#yearList').append(yearResult);

        $('[data-year-trigger]').on('click', function(){
            $('[data-year]').hide();
            $('[data-year="' + $(this).attr('data-year-trigger') + '"]').show();
            return false;
        });
    });
}